@font-face{
  font-display:swap;
  font-family:Circe;
  font-weight:300;
  src:
          url(../../public/fonts/Montserrat-Light.ttf) format("truetype")
}
@font-face{
  font-display:swap;
  font-family:Circe;
  font-weight:400;
  src:
          url(../../public/fonts/Montserrat-Regular.ttf) format("truetype")
}
@font-face{
  font-display:swap;
  font-family:Circe;
  font-weight:600;
  src:
          url(../../public/fonts/Montserrat-SemiBold.ttf) format("truetype")
}
@font-face{
  font-display:swap;
  font-family:Circe;
  font-weight:800;
  src:
          url(../../public/fonts/Montserrat-ExtraBold.ttf) format("truetype")
}
@font-face{
  font-display:swap;
  font-family:CirceGlyphs;
  font-weight:600;
  src:
          url(../../public/fonts/Montserrat-Bold.ttf) format("truetype")
}

:root {
  --red: #E30F18;
  --yellow: #ffdd00;
  --orange: #f49400;
  --green: #009646;
}

body {
  margin: 0;
  padding: 0;
  font-family: Circe, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
